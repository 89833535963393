<template>
	<v-form ref="form">
		<component
			:is="type"
			v-model="value"
			:solo="solo"
			flat
			:hide-details="hide_details"
			:rows="rows"
			:readonly="readonly"
			:disabled="disabled"
			:outlined="outlined"
			:label="$t(placeholder)"
			:rules="rules"
			:color="color"
			@blur="validate()"
			:background-color="bgColor"
		>
			<template v-if="!readonly">
				<!-- STT -->
				<span slot="append">
					<SpeechToText
						:text="value"
						@speechend="value = $event.text"
					></SpeechToText>
				</span>
				<!-- TTS -->
				<span slot="append" class="ms-3">
					<TextToSpeech :text="value"></TextToSpeech>
				</span>
				<!-- Translator -->
				<span slot="append" class="ms-3">
					<Translator :text="value"></Translator>
				</span>
			</template>
			<v-icon
				v-if="show_append_icon"
				color="error"
				slot="append"
				class="ms-3"
				@click="$emit('execute-append-action')"
				>{{ $vuetify.icons.values[append_icon] }}</v-icon
			>
		</component>
	</v-form>
</template>

<script>
import { VTextarea, VTextField } from "vuetify/lib";
import SpeechToText from "@/components/plugins/SpeechToText";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import Translator from "@/components/plugins/Translator.vue";

export default {
	name: "InterviewTextBox",

	props: {
		prop_value: {
			required: true,
		},
		type: {
			type: String,
			default: "v-text-field",
		},
		readonly: {
			default: false,
		},
		rows: {
			default: 5,
		},
		placeholder: {
			default: null,
		},
		show_append_icon: {
			default: false,
		},
		append_icon: {
			default: "close",
		},
		outlined: {
			default: false,
		},
		hide_details: {
			default: true,
		},
		disabled: {
			default: false,
		},
		rules: {
			type: Array,
			default: function() {
				return [];
			},
		},
		solo: {
			default: true,
		},
		color: {
			default: "greenAccent2",
		},
		bgColor: {
			default: "white",
		},
	},

	data() {
		return {
			valid: [],
		};
	},

	components: {
		VTextarea,
		VTextField,
		SpeechToText,
		TextToSpeech,
		Translator,
	},

	computed: {
		value: {
			get() {
				return this.prop_value;
			},
			set(value) {
				this.$emit("input", value);
			},
		},
	},

	methods: {
		validate() {
			if (!this.$refs.form.validate()) {
				return;
			}
			this.$emit("update-value");
		},
	},
};
</script>
